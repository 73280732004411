import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo"
import { filterArrayByType } from '../utility'

const ArticlePage = ({ pageContext }) => {
    console.log("PAGE CONTEXT", pageContext)
    const article = pageContext.node.data;
    console.log(article);

    let articleDate = (article.article_date) ? new Date(article.article_date) : new Date(pageContext.node.first_publication_date);

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let article_date_string = `${months[articleDate.getMonth()]} ${articleDate.getDate()}, ${articleDate.getFullYear()}`;


    let calloutBoxes = filterArrayByType(article.body, "callout_box");
    console.log("callout Boxes", calloutBoxes);

    return (
        <Layout>
        <SEO title={ article.meta_title } image={ article.meta_og_image.url } description={ article.meta_description } canonical={ typeof window !== 'undefined' && (window.location.origin + window.location.pathname)}/>

            <div className="title-bar title-bar-xl banner-work-article-2" style={{ backgroundImage: `url(${article.wide_image.url})`}}>
                <div className="container">
                    <a href="" className="page-scroll"><span className="icn-arrows-down">[Down Arrow]</span></a>
                    <div className="wrapper">
                        <h2 className="page-title">Article</h2>
                        <div className="title-bar-accent"></div>
                    </div>
                </div>
            </div>

            {/*  PAGE BODY  */}
            <section id="body-area">

                <div className="container">

                    <div className="inset margin-bottom-0">

                        <h1 className="headline">{article.article_title.text}</h1>
                        <p className="txt-gray-50 txt-14 no-margin">Author: {article.article_author}</p>
                        <p className="txt-gray-50 txt-14 article-margin">Date: {article_date_string}</p>

                        <div className="row">

                            <div className="col-md-7" dangerouslySetInnerHTML={{ __html: article.article_body_text.html }}></div>
        
                            {
                                calloutBoxes.map( (calloutBox, j) => {
                                    return(
                                        <aside key={ j } className="col-md-5 margin-bottom-30">

                                            <div className="box style-1">

                                                <h3 className="tag">{ calloutBox.primary.callout_box_label.text || "" }</h3>
                                                <div dangerouslySetInnerHTML={{ __html: calloutBox.primary.callout_box_body_text.html || "" }}></div>
                                                {
                                                    calloutBox.primary.callout_box_cta_link &&
                                                    <a href={ calloutBox.primary.callout_box_cta_link.url } className="btn btn-primary">{ calloutBox.primary.callout_box_cta_text } →</a>
                                                }
                                            </div>
                                        </aside>
                                    )
                                })
                            }
                        </div> {/*  /row  */}

                    </div> {/*  /box  */}

                </div> {/*  /container  */}

            </section>
        </Layout>
                )
            }
            
export default ArticlePage;
